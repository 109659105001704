
* {
  font-family: 'Lato', sans-serif;
}
body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
}
.scPage::-webkit-scrollbar {
  width: 0!important;
}
input, textarea, select, button, Input, Button, Select {
	outline: none!important;
  resize: none!important;
  color: #000!important;
}

.updown {
  animation: updownEffect 2s infinite;
  transition: 300ms ease-in-out;
}
@keyframes updownEffect {
  0% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(8px);
  }
}

input[type=radio] {
  border: 0px;
  width: 22px;
  height: 22px;
}